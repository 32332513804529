function creativeHandler() {
  $('.creative-source input[type=radio]').on('change', function() {
    // $('.additional-content').addClass('hide');
    $(this).parents('.creative-sources').children('.creative-source').children('.additional-content').addClass('hide'); //.children('.additional-content').addClass('hide'); //$('.additional-content').addClass('hide');
    $(this).siblings('.additional-content').removeClass('hide');
  });
}

document.addEventListener('turbo:render', creativeHandler);
document.addEventListener('DOMContentLoaded', creativeHandler);
