import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('body').on('click', '.creative-collection .creative-item', (event) => {
      event.preventDefault();

      const selectedCreative = $(event.currentTarget);
      const creativeId = selectedCreative.data('id');

      const id = selectedCreative.parents('.additional-content').data('id');
      $('#creative-item-value-' + id).val(creativeId);

      selectedCreative.parent('.creative-collection').children('.creative-item').removeClass('selected');
      selectedCreative.addClass('selected');
    });
  }
}
