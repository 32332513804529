import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    name: String,
  }

  connect() {
    // alert('loaded');
    this.element.dataset.action = "change->select#update";
  }

  update() {
    const url = new URL(this.urlValue);
    url.searchParams.append(this.nameValue, this.element.value);

    Turbo.visit(url, { action: "replace" });
  }
}
