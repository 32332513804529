// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import jquery from "jquery"

window.jQuery = jquery
window.$ = jquery

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import "./creative_picker"

import "./controllers"


// enable tooltip of bootstrap
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
